:root {
  --l-primary-50: #f1f7ff;
  --l-primary-100: #e7f5ff;
  --l-primary-200: #b2d2ff;
  --l-primary-300: #ace1ff;
  --l-primary-400: #71cbff;
  --l-primary-500: #1c6fe2;
  --l-primary-600: #0c5fd1;
  --l-primary-700: #0463b2;
  --l-primary-800: #0b3c66;
  --l-primary-900: #0e2941;

  --l-primary-tenant-button-text: white;
  --l-primary-tenant-500:red;
  --l-primary-tenant-hover-button:pink;

  --l-secondary-tenant-color: #d27000;
  --l-secondary-tenant-button-text: white;
  --l-secondary-tenant-hover-button:orange;

  --l-tertiary-tenant-color:green;

  --l-grey-100: #fdfafa;
  --l-grey-200: #dfdfdf;
  --l-grey-300: #b5b5b5;
  --l-grey-400: #9e9e9e;
  --l-grey-500: #888888;
  --l-grey-600: #6f6f6f;
  --l-grey-700: #555555;
  --l-grey-800: #303030;
  --l-grey-900: #222222;
  --l-grey-000: #ffffff;

  --l-pink-100: #f2cee3;
  --l-pink-500: #cf2d79;
  --l-pink-700: #841c50;

  --l-teal-100: #d2ecef;
  --l-teal-600: #3d8893;
  --l-teal-700: #306b74;

  --l-positive-100: #d3efd1;
  --l-positive-200: #a9dfa6;
  --l-positive-700: #347426;
  --l-positive-800: #27551e;

  --l-negative-100: #f5d5da;
  --l-negative-200: #ebacb4;
  --l-negative-500: #d63e47;
  --l-negative-600: #b0343d;
  --l-negative-700: #892c33;
  --l-negative-800: #612329;

  --l-warning-100: #fbe6d4;
  --l-warning-200: #f7cdaa;
  --l-warning-700: #98582a;
  --l-warning-800: #6c4221;

  --l-yellow-550: #fdda01;
  --l-yellow-400: #f8d757;
}

.container-laasy {
  padding-right: 7.2rem;
  padding-left: 6.7rem;
  font-family: Helvetica,sans-serif !important;

  @media screen and (max-width: 1020px) {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }
  @media screen and (max-width: 600px) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.l-font-helvetica {
  font-family: Helvetica,sans-serif;
}

.l-heading {
  font-weight: 400;
  font-size: 64px;
}

.l-heading-bold {
  font-weight: 700;
  font-size: 64px;
}

.l-heading-sm {
  font-weight: 400;
  font-size: 44px;
}
.l-heading-sm-bold {
  font-weight: 700;
  font-size: 44px;
}

.l-title {
  font-weight: 400;
  font-size: 32px;
}

.l-title-bold {
  font-weight: 700;
  font-size: 32px;
}

.l-title-sm {
  font-weight: 400;
  font-size: 24px;
}

.l-title-sm-bold {
  font-weight: 700;
  font-size: 24px;
}

.l-sub-title {
  font-weight: 400;
  font-size: 20px;
}

.l-sub-title-bold {
  font-weight: 700;
  font-size: 20px;
}

.l-body {
  font-weight: 400;
  font-size: 16px;
}

.l-body-bold {
  font-weight: 700;
  font-size: 16px;
}

.l-body-sm {
  font-weight: 400;
  font-size: 14px;
}

.l-body-sm-bold {
  font-weight: 700;
  font-size: 14px;
}

.l-body-xs {
  font-weight: 400;
  font-size: 12px;
}

.l-body-xs-bold {
  font-weight: 700;
  font-size: 12px;
}

.l-caption-sm {
  font-weight: 400;
  font-size: 10px;
}

.l-caption-sm-bold {
  font-weight: 700;
  font-size: 10px;
}

/* Authentication form styling*/

.l-form-container {
  margin: 1rem auto;
  width: 388px;
}

.l-form-heading {
  padding: 2rem 0rem 3rem 0rem;
}

.l-form-input {
  border: 1px solid var(--grey-300, #D1D1D1);
  border-radius: 12px;
  height: 48px;
  padding: 0rem 0.75rem;
  outline: none;
}

.l-form-input:focus {
  border: 1px solid var(--l-grey-900);
}

.l-form-input-error {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  img {
    height: 1rem;
  }
}

.l-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.l-form-button {
  margin: 1rem auto;
  height: 3.5rem;
  width: 100%;
  justify-content: center;
}

.l-form-button:disabled {
  opacity: 0.4;
  border: none;
}

.p-inputtext:enabled:focus{
  box-shadow:none!important;
  border: 1px solid var(--l-grey-900)!important;
}

.mt-6 {
  margin-top: 6px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-14 {
  margin-top: 14px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mt-18 {
  margin-top: 18px !important;
}
.mt-32 {
  margin-top: 32px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-56 {
  margin-top: 56px !important;
}
.mt-48 {
  margin-top: 48px !important;
}
.mt-75 {
  margin-top: 75px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.mb-33 {
  margin-bottom: 33px !important;
}
.mb-43 {
  margin-bottom: 43px !important;
}
.mb-48 {
  margin-bottom: 48px !important;
}
.mb-100 {
  margin-bottom: 100px !important;
}
.mb-104 {
  margin-bottom: 104px !important;
}
.pr-11 {
  padding-right: 11px !important;
}
.pl-12 {
  padding-left: 12px !important;
}
.p-15 {
  padding: 15px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pt-32 {
  padding-top: 32px !important;
}

@media screen and (max-width: 768px) {
  .l-form-container {
    width: 100%;
    padding: 1rem;
  }
}

/*alert styling(to be added in respoective theming file)*/
.l-alert-container {
  border-radius: 24px;
  display: flex;
  padding: 1rem;
  margin-bottom: 1rem;
}

.l-alert-icon {
  margin-right: 1rem;
}

/*alert styling for light theme */
.l-alert-light-info {
  background-color: var(--l-primary-100);
  border: 1px solid var(--l-primary-200);
}

.l-alert-light-positive {
  background-color: var(--l-positive-100);
  border: 1px solid var(--l-positive-200);
}

.l-alert-light-warning {
  background-color: var(--l-warning-100);
  border: 1px solid var(--l-warning-200);
}

.l-alert-light-negative {
  background-color: var(--l-negative-100);
  border: 1px solid var(--l-negative-200);
}

/*alert styling for dark theme */
.l-alert-dark-info {
  background-color: var(--l-primary-800);
  border: 1px solid var(--l-primary-700);
  color: white;
}

.l-alert-dark-positive {
  background-color: var(--l-positive-800);
  border: 1px solid var(--l-positive-700);
  color: white;
}

.l-alert-dark-warning {
  background-color: var(--l-warning-800);
  border: 1px solid var(--l-warning-700);
  color: white;
}

.l-alert-dark-negative {
  background-color: var(--l-negative-800);
  border: 1px solid var(--l-negative-700);
  color: white;
}
.p-dialog {
  border: none !important;
  ::-webkit-scrollbar {
    width: 0.4rem !important;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
  }
}

.invalid-autocomplete {
  border: 2px solid var(--negative-500, #D63E47)!important;
  border-radius: 0.9rem;
}
.m-0 {
  margin: 0;
}

.white-space-normal {
  white-space: normal !important;
}

.profile {
  .profile-picture-container {
    @extend .m-0;
    cursor: pointer;
    .profile-picture {
      &,
      .img-initials {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        color: #fff;
        text-transform: uppercase !important;
        align-items: center !important;
        justify-content: center !important;
        display: flex !important;
        font-size: 16px;
      }
    }
  }
}
