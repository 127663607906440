/* You can add global styles to this file, and also import other style files */
@import './scss/styles.scss';
@import './scss/ball-atom.scss';

body::-webkit-scrollbar {
    width: 0.8rem;
  }

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

body::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
}

body{
  background-color: white;
}

ngx-otp-input {
  .motp-input{
    border: 1px solid var(--grey-300, #D1D1D1)!important;
    height: 4rem !important;
    width: 3.3rem !important;
  }
  .ngx-otp-input-container{
    justify-content: space-between;
  }
  .motp-input-filled{
    border-radius: 0.75rem!important;
    border: 2px solid #2222!important;
  }
}

@media screen and (max-width:564px) {
  ngx-otp-input {
    .motp-input{
      height: 4rem !important;
      width: 3rem !important;
    }
  }
}

@media screen and (max-width:400px) {
  ngx-otp-input {
    .motp-input{
      height: 3.7rem !important;
      width: 2.7rem !important;
    }
  }
}

@media screen and (max-width:300px) {
  ngx-otp-input {
    .motp-input{
      height: 3.3rem !important;
      width: 2.5rem !important;
    }
  }
}
